<template>
  <div>
    <v-row>
      <v-sheet class="text text-body-1 font-weight-regular">{{ type }}</v-sheet>
    </v-row>
    <v-row>
      <v-col sm="12" md="4" lg="4">
        <v-checkbox
          v-if="readPermission"
          class="ma-0 pa-0"
          :data-cy="'read_' + type"
          dense
          label="read"
          color="hqsOrange"
          @change="set($event, 'read')"
        ></v-checkbox>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <v-checkbox
          v-if="writePermission"
          class="ma-0 pa-0"
          :data-cy="'write_' + type"
          dense
          label="write"
          color="hqsOrange"
          @change="set($event, 'write')"
        ></v-checkbox>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <v-checkbox
          v-if="deletePermission"
          class="ma-0 pa-0"
          :data-cy="'delete_' + type"
          dense
          label="delete"
          color="hqsOrange"
          @change="set($event, 'delete')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },

  props: {
    type: String,
    readPermission: Boolean,
    writePermission: Boolean,
    deletePermission: Boolean,
  },

  methods: {
    ...mapMutations('tokens', ['setPermission']),

    set(value, permission) {
      this.setPermission([this.type, permission, value])
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox {
  padding-top: 0px !important;
}
</style>
